import styled from "@emotion/styled";

import { Rive } from "../../../../../../../components/ui/New/Rive/Rive";
import { Title } from "../../../../../../../components/ui/New/Title/Title";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 48px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledContent = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    max-width: 600px;
    width: 50%;
    margin-right: 56px;
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 32px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 48px !important;
  }
`;

export const StyledStep = styled.div`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.21;
  margin-bottom: 24px;
`;

export const StyledDescription = styled.div`
  color: ${({ theme }) => theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.67;
  min-height: 90px;
`;

export const StyledImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: ${(p) => (p.mobile ? "none" : "block")};
    width: 483px;
    padding-bottom: 256px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 500px;
  }
`;

export const StyledAnimation = styled(Rive)`
  position: absolute;
  top: 0;
  right: -69px;
  width: 621px;
  height: 422px;

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    & > canvas {
      width: 0;
      height: 0;
    }
  }
`;

export const StyledAnimationWrapper = styled.div`
  position: relative;
  display: none;
  width: 0;
  height: 0;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;
    width: 621px;
    height: 422px;
  }
`;
